/* You can add global styles to this file, and also import other style files */
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0 !important;
  height: 100%;
}
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
// Colors

// Fonts

// Text

// Breakpoints
$smbr: max-width 600px;
$mdbr: 768px;
$lgbr: 992px;
$xlbr: 1200px;

@mixin sm-down {
  @media (max-width: 440px) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 440px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}
